/*TOP NAV BAR */
.auria-logo {
    height: 100%;
    width: auto;    
}
@media (min-width:320px)  {
    .auria-logo {
       width: 100px; 
    }
}
@media (min-width:481px)  { 
    .auria-logo {
        width: 130px; 
    }
}

@media (min-width:760px)  { 
    .auria-logo {
        width: 150px; 
    }
 }

@media (min-width:961px)  { 
    .auria-logo {
        width: 170px;
    }    
 }

/*MAP MEDIA QUERIES*/
.map-container {
    width: 100%;
    height: 90vh;
    /* position: relative; */
}
@media (min-width:320px)  {
    .map-container {
        height: 83vh;
    }
}
@media (min-width:481px)  { 
    .map-container {
    height: 88vh;
} }
@media (min-width:641px)  { 
    .map-container {
    height: 85vh; }
}

@media (min-width:760px)  { 
    .map-container {
        height: 88vh; }    
 }

@media (min-width:961px)  { 
    .map-container {
        height: 84vh; }
    
 }
@media (min-width:1025px) { 
    .map-container {
        height: 85vh; }
 }
@media (min-width:1281px) {
    .map-container {
        height: 88vh; }
}

/* DATE PICKER INPUT*/

/* Removes the clear button from date inputs */
input[type="date"]::-webkit-clear-button {
    display: none;
}

/* Removes the spin button */
input[type="date"]::-webkit-inner-spin-button { 
    display: none;
}

/* Always display the drop down caret */
input[type="date"]::-webkit-calendar-picker-indicator {
    color: #2c3e50;
}

/* A few custom styles for date inputs */
input[type="date"] {
    appearance: none;
    -webkit-appearance: none;
    color: #95a5a6;
    font-family: "Helvetica", arial, sans-serif;
    font-size: 18px;
    border:1px solid #ecf0f1;
    background:#ecf0f1;
    padding:5px;
    display: inline-block !important;
    visibility: visible !important;
}

input[type="date"], focus {
    margin-bottom: calc(1vw + 1px);
    background-color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    border-color: rgb(133, 133, 133);
    font-size: 1rem;
    margin: 8px 8px 8px 0;
    color: rgba(0, 0, 0, 0.87);
    padding: 16.5px 14px;
    border-radius: 5px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

