body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.input {
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 1rem;
  letter-spacing: 0.8px;
  padding: 16px 8px;
  width: 100%;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: white;
  width: 22ch;
}

.input:hover {
  border-color: #2979ff;
}

.input:focus {
  outline: none !important;
  border:2px solid #2979ff;
}